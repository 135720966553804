export const FILTER_ITEM_TYPES = {
  KEYWORD: 'KEYWORD',
  DURATION: 'DURATION',
  LOOKUP: 'LOOKUP',
  BOOLEAN: 'BOOLEAN',
  DATETIME: 'DATETIME',
  LONG: 'LONG',
  INTEGER: 'INTEGER',
  BYTE: 'BYTE',
  TEXT: 'TEXT',
};

export const TYPE_COMPARATOR = {
  KEYWORD: 'CONTAINS',
  TEXT: 'CONTAINS',
  DURATION: 'IS',
  LOOKUP: 'IS',
  BOOLEAN: 'IS',
  DATETIME: 'IS',
  LONG: 'IS',
  BYTE: 'IS',
};

export const KEYWORD_CONTAINS_TYPES = [
  FILTER_ITEM_TYPES.KEYWORD,
  FILTER_ITEM_TYPES.TEXT,
];

export const EXACT_FILTERS = [
  'audioChannels',
  'pmam.airtableStatus',
  'pmam.copyrightOwner',
  'episodeNo',
  'episodeTitle',
  'featureTitle',
  'title',
  'rating',
  'seasonNo',
  'seriesTitle',
  'gtmVersionUnifiedId',
];

export const WORKORDERS_CONTAINS_FILTERS = [
  'asset_episodeTitle',
  'asset_gtm_featureTitle',
  'asset_seriesTitle',
  'state_translatorState',
];
// TODO: Might not be needed if data coming from database
export const STUDIO_SUPPLY_ORDERS_CONTAINS_FILTERS = [
  'asset_episodeTitle',
  'asset_gtm_featureTitle',
  'asset_seriesTitle',
  'state_translatorState',
];

export const API_ENDPOINTS = {
  ACTIVITY_LOGS: '/API/gql/assets/activity-logs',
  WORKORDER_LOGS: '/API/gql/assets/workorder-logs',
  ASSETS: '/API/gql/assets',
  LANGUAGE_CODES: '/API/gql/codes/languages',
  LANGUAGE_OPTIONS: '/API/gql/options/languages',
  ASPECT_RATIO_OPTIONS: '/API/gql/options/aspect-ratios',
  VERSION_TYPES: '/API/gql/options/version-types',
  AIRTABLE_RECORDS: '/API/gql/air-table/records',
  COLUMNS: '/API/columns',
  DROPDOWN_OPTIONS: '/API/dropdown-options',
  LOGGED_IN_USER: '/API/introspect',
  FEATURE_FLAGS: '/API/feature-flags',
  USER_DETAILS: '/API/principal',
  PMAM_ASSET: '/API/gql/pmam-asset',
  SAVED_SEARCHES: '/API/gql/saved-searches',
  TITLE_GTM_ID: '/API/gtm-gql/title-gtmId',
  DATE_GTM_ID: '/API/gtm-gql/date-gtmId',
  TITLE_VERSIONS_GTM_ID: '/API/gtm-gql/title-versions-gtmId',
  WATERMARKING_TOKEN: '/API/wm-gql/token-generation', // Deprecated
  WATERMARKING_ORDER: '/API/wm-gql/proxy-order',
  WATERMARKING_URL: '/API/wm-gql/proxy-address',
  ASSET_PROXY: '/API/wm-gql/asset-proxy',
  PMAM_VIDEO_DATA: '/API/pmam-video-proxy/video-data',
  PMAM_VIDEO_FILE: '/API/pmam-video-proxy/video-file',
  WM_VIDEO_PROXY: '/API/wm-gql/video-proxy',
  VIDEO_PROXY: '/API/video-proxy/proxy',
  UPDATE_WATERMARKING_ORDER: '/API/wm-gql/update-proxy-order',
  WORK_ORDERS_REST_API: '/API/work-orders',
  DESTINATION_PARAMETERS: '/API/studio-supply-gql/parameters/destination',
  VALIDATE_ORDER_CREATION: '/API/studio-supply-gql/validate-order-creation',
  WORK_ORDERS: '/API/gql/workorders',
  CREATE_WORK_ORDERS: '/API/gql/create-workorders',
  WORK_ORDER_PROFILES: '/API/gql/workorder-profiles',
  VARIANTS_GTM_UNIFIED_ID: '/API/gtm-gql/variants-gtmUnifiedId',
  CREATE_PMAM_ASSET: '/API/gql/create-asset',
  UPDATE_PMAM_ASSET: '/API/gql/update-asset',
  BULK_UPDATE_PMAM_ASSET: '/API/gql/update-asset-bulk',
  SEARCH_STUDIO_SUPPLY_ORDERS: '/API/studio-supply-gql/search-orders',
  STUDIO_SUPPLY_ORDERS_SEARCH_FIELDS: '/API/studio-supply-gql/searchable-fields',
  RETRY_STUDIO_SUPPLY_ORDER: '/API/studio-supply-gql/retry-supply-order',
  STUDIO_SUPPLY_ORDER_AVAILABILITY: '/API/studio-supply-gql/order-availability',
  STUDIO_SUPPLY_BULK_ORDER: '/API/studio-supply-gql/supply-bulk-order',
  SUGGEST_FEATURE_TITLES: '/API/gtm-gql/suggest/feature-titles',
  SUGGEST_SERIES_TITLES: '/API/gtm-gql/suggest/series-titles',
  SUGGEST_SEASON_NUMBERS: '/API/gtm-gql/suggest/season-numbers',
  SUGGEST_EPISODE_TITLES: '/API/gtm-gql/suggest/episode-titles',
  FORMAT_PARAMETERS: '/API/studio-supply-gql/format-parameters',
  MERLIN: '/API/gql/send-to-merlin',
  TOGGLE_APPROVE_ASSETS: '/API/gql/asset-bulk-spot-vault-approval',
  SET_RESTRICTED_STATUS: '/API/gql/set-restricted-status',
  SET_BULK_RESTRICTED_STATUS: '/API/gql/set-bulk-restricted-status',
  APPROVE_ASSETS: '/API/gql/approve-assets',
  NETWORKS: '/API/gql/get-networks',
  DELETE_COLLECTION: '/API/collections/delete-collection',
  SEARCH_COLLECTIONS: '/API/collections/search-collections',
  RENAME_COLLECTION: '/API/collections/rename-collection',
  CREATE_COLLECTION: '/API/collections/create-collection',
  ADD_TO_COLLECTION: '/API/collections/add-to-existing-collection',
  COLLECTION_ASSETS: '/API/gql/assets/get-assets-for-collection',
  GET_COLLECTION_BY_ID: '/API/collections/get-collection-by-id',
  REMOVE_ASSET_FROM_COLLECTION: '/API/collections/remove-assets-from-collection',
  MEDIATOR_X_VIDEO_DATA: '/API/mediator-x-video-proxy/video-data',
  MEDIATOR_X_VIDEO_FILE: '/API/mediator-x-video-proxy/video-file',
  MEDIATOR_8_LEGACY_PROXY_FILE: '/API/mediator-8-legacy-proxy/video-file',
  GDL_VIDEO_DATA: '/API/gdl-video-library/video-data',
  GDL_VIDEO_FILE: '/API/gdl-video-library/video-file',
  MASTER_SUBMASTER_SEARCH: '/API/master-submaster/search',
  DELETE_ASSETS: '/API/gql/delete-asset-bulk',
  MATERIAL_TYPES: '/API/gql/options/material-types',
  GIW_USER: '/API/giw/pmam',
};

export const FEATURE_FLAG_ENDPOINT_INTERVAL = 5 * 60 * 1000;
